<template>
  <CheckAccessoryMobile v-if="isMobileAgent()" :item="item" @cancel="cancel" @refreshData="refreshData" />
  <CheckAccessoryDesktop v-else :item="item" @cancel="cancel" @refreshData="refreshData" />
</template>

<script>

export default {
  name: "CheckAccessory",
  components: {
    CheckAccessoryMobile: () => import('@/components/material/CheckAccessoryMobile'),
    CheckAccessoryDesktop: () => import('@/components/material/CheckAccessoryDesktop'),
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    refreshData(type) {
      this.$emit('refreshData', type)
    },
  }
}
</script>

<style scoped>

</style>
